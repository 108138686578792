import { useSelector } from 'react-redux';
import { StateProps } from '../../redux/reducer';
import { FaGlobe,FaUser,FaListUl,FaSearch,FaUserFriends} from 'react-icons/fa';
import { colors } from '../../config/constants';
import React from 'react'
const Dashboard = () => {
  const backgroundImage = [
    "bg-[#3291a8]",
    "bg-[#3263a8]",
    "bg-[#3291a8]",
    "bg-[#3263a8]",
    "bg-[#3291a8]",
    "bg-[#3263a8]",
  ];
  const ccc = useSelector((state: StateProps) => state.counter);

  console.log('isLoading', ccc);
  const cardsData = [
    {
      lable: 'Mapped Websites',
      value: '45',
      icon:<FaGlobe size={25} />
    },
    {
      lable: 'Role',
      value: '420',
      icon:<FaUserFriends size={25} />
    },
    {
      lable: 'Users',
      value: '5647',
      icon:<FaUser size={25} />
    },
    {
      lable: 'New Websites',
      value: '784',
      icon:<FaGlobe size={25} />
    },
    {
      lable: 'Categories',
      value: '100',
      icon:<FaListUl size={25} />
    },
    {
      lable: 'Keywords',
      value: '42564',
      icon:<FaSearch size={25} />
    },
  ];

  return (
    <div className="m-4">    
    <div className="bg-white my-4 p-2 shadow-lg text-white  ">
        {/* <div className="bg-sky-700 p-2">Information</div> */}
        <div className="grid grid-cols-2 gap-1 md:grid-cols-6 sm:grid-cols-3 sm:gap-2">
          {cardsData.map((item, index) => {
            return (
              <div
                key={index}
                className={`${backgroundImage[index]} sm:mr-1 flex items-center justify-between p-1`}
              >
                <div>
                  <div>{item.lable}</div>
                  <div className="text-2xl">{item.value}</div>
                </div>
               {item.icon}
              </div>
            );
          })}
        </div>
      </div>  
      <div className="h-96 bg-white text-white my-4 p-2 shadow-lg ">
        <div className={`${colors.primary} p-2  `}>Information</div>
       {ccc}
      </div>    
    </div>
  );
};

export default Dashboard;
