import React from 'react'

const NotFound = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen'>
        <div className='text-sky-700 font-bold text-lg'>Sorry! </div>
        <div className='text-sky-700 font-bold text-[50px]'> Page Not Found!</div>
    </div>
  )
}

export default NotFound