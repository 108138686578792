import { Link } from "react-router-dom";
import logo from "../../assets/owlbot_logo_login.png";
import Footer from "../../components/Footer/Footer";
import { FaUserCircle, FaKey } from "react-icons/fa";
import React from "react";

const Login = () => {
  return (
    <div>
      <div className="flex justify-center items-center sm:h-screen ">
        <div className="border-2 w-96 h-[400] p-8 flex flex-col justify-center shadow-lg rounded-sm bg-white">
          <img src={logo} />
          <div className="mb-1">
            <div className="text-[#9c9898] text-md">Username</div>
            <div className="my-1 border-2 flex items-center">
              <div className="h-10 w-12 bg-[#e1e6e3] flex items-center justify-center">
                <FaUserCircle color="#a7a8a7" size={25} />
              </div>

              <input
                placeholder="sunil@gmail.com"
                className="h-10 block w-full text-sm text-slate-500 focus:outline-none pl-2 rounded-sm border-1	"
              />
            </div>
            {/* <div className="text-sm text-[red]">Please enter username</div> */}
          </div>
          <div className="mb-6">
            <div className="text-[#9c9898] text-md">Password</div>
            <div className="my-1 border-2 flex items-center">
              <div className="h-10 w-12 bg-[#e1e6e3] flex items-center justify-center">
                <FaKey color="#a7a8a7" size={25} />
              </div>
              <input
                placeholder="sunil@45675"
                className="h-10 block w-full text-sm text-slate-500 focus:outline-none pl-2 rounded-sm	"
              />
            </div>
            {/* <div className="text-sm text-[red]">Please enter password</div> */}
          </div>
          <Link to={"/dashboard"}  className="h-10 bg-blue-500 rounded-sm flex items-center justify-center cursor-pointer ">
            <div className="text-white font-medium p-2">LOGIN</div>
          </Link>
          <div className="flex justify-center">
            <div className="mt-3 text-blue-500 cursor-pointer ">
              Forgot Password?
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
