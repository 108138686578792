
import logo from "../../assets/owlbot_logo_login.png";
import user from "../../assets/msd.jpg";
import {
  FaAngleDown,
  FaBars,
  FaUserCircle,
  FaSignOutAlt,
  FaKey,
} from "react-icons/fa";
import Popover from "@mui/material/Popover";
import { Link } from "react-router-dom";
import React, { useState } from "react";
const Header = () => {
  const profileData = [
    {
      name: "Profile",
      url: "profile",
      icon: <FaUserCircle className="mr-2" />,
    },
    {
      name: "Change Password",
      url: "profile",
      icon: <FaKey className="mr-2" />,
    },
    {
      name: "Logout",
      url: "profile",
      icon: <FaSignOutAlt className="mr-2" />,
    },
  ];
  const shortcuts = [
    {
      name: "aaaa",
      url: "profile",
      icon: <FaUserCircle className="mr-2" />,
    },
    {
      name: "bbbb",
      url: "profile",
      icon: <FaKey className="mr-2" />,
    },
    {
      name: "cccc",
      url: "profile",
      icon: <FaSignOutAlt className="mr-2" />,
    },
  ];
  const [profileanchorEl, setProfileAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [shortcutanchorEl, setShotCutAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleShortCutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShotCutAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };
  const handleShortcutClose = () => {
    setShotCutAnchorEl(null);
  };
  const openprofile = Boolean(profileanchorEl);
  const openshortcut = Boolean(shortcutanchorEl);
  const id_profile = openprofile ? "simple-popover" : undefined;
  const id_shortcut = openshortcut ? "simple-popover2" : undefined;

  return (
    <div className="h-16 flex items-center justify-between shadow-sm bg-white">
      <div className="flex items-center justify-between sm:justify-start ">
        {/* <FaBars size={22} className="ml-2 sm:hidden" /> */}
        <div className="sm:w-60">
          <img src={logo} className="h-[35px] mr-12 text-lg sm:ml-10" />
        </div>
        <div className="text-[#02134d] text-lg font-bold sm:ml-2">
          Dashboard
        </div>
      </div>

      <div className="h-16 sm:w-96 flex items-center sm:justify-between justify-end">
        <div className="h-16 w-1/2 sm:flex hidden items-center justify-center text-[#02134d] border-r-2 border-slate-300">
          <button className="flex items-center" onClick={handleShortCutClick}>
            <div> Shortcut to</div>
            <FaAngleDown size={25} className="ml-2 cursor-pointer " />
          </button>{" "}
          <div>
            <Popover
              className="mt-[20px] ml-[-35px] cursor-pointer"
              id={id_shortcut}
              open={openshortcut}
              anchorEl={shortcutanchorEl}
              onClose={handleShortcutClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-[180px] text-slate-900">
                {shortcuts.map((item, index) => {
                  return (
                    <div key={index} className="flex items-center p-2 border-b-2 border-slate-100 hover:bg-sky-700 hover:text-white">
                      {item.icon}
                      <div className="">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
        </div>

        <div>
          <button className="flex sm:items-center" onClick={handleClick}>
            <div className="sm:flex hidden">Sunil Kumar</div>
            <div>
              <img
                src={user}
                height={40}
                width={40}
                className="rounded-full mx-4 border-2 border-sky-700"
              />
            </div>
          </button>{" "}
          <div>
            <Popover
              className="mt-[14px] mr-2 ml-0 cursor-pointer"
              id={id_profile}
              open={openprofile}
              anchorEl={profileanchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-[180px] text-slate-900">
                {profileData.map((item, index) => {
                  return (
                    <div key={index} className="flex items-center p-2 border-b-2 border-slate-100 hover:bg-sky-700 hover:text-white">
                      {item.icon}
                      <div className="">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
