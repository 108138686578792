import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackBarProps {
  isOpen: boolean;
  message: string;
  handleClose: () => void;
  type:any
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const colors = ['success', 'error', 'warning', 'info'];

const SnackBar = (props: SnackBarProps) => {
  const { isOpen, handleClose, message,type } = props;
  //   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //     // if (reason === 'clickaway') {
  //     //   return;
  //     // }
  //     setOpen(false);
  //   };

 

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default SnackBar;
