import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FaSearch, FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import styles from '../../styles/muitable.module.css';
import PopOver from '../Popover/PopOver';
import { colors } from '../../config/constants';
import Popup from '../Popup/Popup';
import Button from '../Button/Button';
import Input from '../Input/Input';
import SnackBar from '../SnackBar/SnackBar';

interface Column {
  label: string;
  minWidth?: number;
  align?: any;
  format?: (value: number) => string;
}
interface Data {
  id: number;
  name?: string;
  code?: string;
  population?: number;
}

interface MuiTableProps {
  columns: Column[];
  rows: Data[];
  onEditHandler?: (arg: number) => void;
  onDeleteHandler?: (arg: number) => void;
}

const MuiTable = (props: MuiTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const { columns, rows, onEditHandler, onDeleteHandler } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewAction = (id: number) => {
    return (
      <div
        onClick={() => alert(id)}
        className="bg-sky-700 w-fit px-3 py-1 text-white rounded-sm cursor-pointer"
      >
        View
      </div>
    );
  };
  const content = () => <div></div>;

  const doSubmit = () => {
    setIsSnackBarOpen(true);
  };

  const showItem = (value: string, columnname: string, id: number) => {
    if (columnname === 'action') {
      return (
        <div className="flex items-center">
          <FaPencilAlt
            onClick={() => onEditHandler && onEditHandler(id)}
            color="blue"
            className="border-2 border-sky-500 w-6 h-6 p-1 rounded-sm cursor-pointer mr-1"
          />
          <FaTrashAlt
            onClick={() => onDeleteHandler && onDeleteHandler(id)}
            color="red"
            className="border-2 border-red-500  w-6 h-6 p-1 rounded-sm cursor-pointer"
          />
        </div>
      );
    } else if (columnname === 'viewdetails') {
      return viewAction(id);
    } else {
      return value;
    }
  };

  return (
    <div>
      <div
        className={`${colors.primary} text-white sm:flex items-center justify-between rounded-sm mb-2`}
      >
        <div className="flex item-center">
          <div className={`${colors.primary} py-4 px-4 font-bold`}>
            Manage Websites
          </div>
          <PopOver />
        </div>

        <div className="bg-white px-2 sm:mx-4 flex items-center">
          <FaSearch size={20} className="bg-white" color="#dedfe0" />
          <input className="p-1 outline-none text-[gray]" placeholder="Search for" />
        </div>
      </div>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" align="left">
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow>
                {columns.map((column: Column, index: number) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    classes={{ root: styles.tableHeadCell }}
                  >
                    {column.label.toUpperCase()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column: Column, index: number) => {
                        const value = row[column.label];
                        return (
                          <TableCell
                            key={column.label}
                            align={column.align}
                            classes={{ root: styles.tablebody }}
                          >
                            {showItem(value, column.label, row['id'])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popup
        heading={'Are you sure want to delete?'}
        content={content}
        doSubmit={doSubmit}
        isOpen={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        submitLable={'YES'}
        cancelLable={'NO'}
      />
    </div>
  );
};
export default MuiTable;
