import React from "react";
import "./App.css";
import Router from "./Router";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { StyledEngineProvider } from "@mui/material/styles";

const App = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <Router />
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
