import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './screens/Dashboard/Dashboard';
import Login from './screens/Login/Login';
import NotFound from './components/NotFound/NotFound';
import Roles from './screens/Roles/Roles';
import Users from './screens/Users/Users';
import React from "react";

const Router = () => {
  const Layout = () => (
    <div className="bg-slate-100">
      <Header />
      <div className="flex w-full">
        <div className="sm:w-60">
          <Sidebar />
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );

  return (
    <BrowserRouter>
      <div className="bg-slate-300">
        <Routes>
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/users" element={<Users />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Router;
