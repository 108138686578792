import React, { useState } from "react";
import { Popover } from "@mui/material";
import { FaFilter } from "react-icons/fa";
const PopOver = () => {
  const category = [
    {
      name: "Performance assessment investigator",
      url: "profile",
    },
    {
      name: "bbbb",
      url: "profile",
    },
    {
      name: "cccc",
      url: "profile",
    },
    {
      name: "aadsfds Performance Performance Performance Performance Performance",
      url: "profile",
    },
    {
      name: "bbbb",
      url: "profile",
    },
    {
      name: "cccc",
      url: "profile",
    },
    {
      name: "aadsfds Performance Performance Performance Performance Performance",
      url: "profile",
    },
    {
      name: "bbbb",
      url: "profile",
    },
    {
      name: "cccc",
      url: "profile",
    },
  ];

  const [shortcutanchorEl, setShotCutAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleShortCutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShotCutAnchorEl(event.currentTarget);
  };

  const handleShortcutClose = () => {
    setShotCutAnchorEl(null);
  };

  const openshortcut = Boolean(shortcutanchorEl);

  const id_shortcut = openshortcut ? "simple-popover2" : undefined;
  return (
    <div>
      <button
        className="bg-blue-700 py-2 px-3 flex items-center m-2"
        onClick={handleShortCutClick}
      >
        <FaFilter size={20} className="bg-blue-700 pr-2" color="white" />
        <div> Filter by category</div>
      </button>{" "}
       <div>
            <Popover
              className="mt-2 cursor-pointer"
              id={id_shortcut}
              open={openshortcut}
              anchorEl={shortcutanchorEl}
              onClose={handleShortcutClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-[180px] text-slate-900 h-56 overflow-auto">
                {category.map((item, index) => {
                  return (
                    <div key={index} className="flex items-center p-1 border-b-2 border-x-2 overflow-x whitespace-nowrap    ">                     
                      <div>{item.name}</div>
                    
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
    </div>
  );
};

export default PopOver;
