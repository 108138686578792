import React from 'react'

interface BreadCrumbProps{
  BreadCrumButton : any;
}

const BreadCrumb = (props:BreadCrumbProps) => {
  const crumbs = ['Dashboard', 'Manage Roles', 'Manage Keywords'];  

  return (
    
    <div className="bg-white flex items-center justify-between text-sm text-[gray]">
      <nav className="flex items-center" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3 pl-1">
          {crumbs.map((list, index) => (
            <li className="inline-flex items-center" key={index}>
              <a
                href="#"
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
              >
                {index === 0 && (
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                )}
                {list}
              </a>
              {index !== crumbs.length - 1 && (
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </li>
          ))}
        </ol>
      </nav>
      <div>
        {props.BreadCrumButton}
      </div>
      
    </div>
  );
};

export default BreadCrumb;
