import React from "react";

const Footer = () => {
  return (
    <div className="bg-white h-10 w-full bottom-0 flex items-center justify-end text-sm text-[gray] pr-5">
      Copyright © 2020 Excelsoft Technologies Pvt Ltd
    </div>
  );
};

export default Footer;
