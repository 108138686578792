import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '../Button/Button';

interface PopupProps {
  heading: string;
  isOpen: boolean;
  onClose: () => void;
  doSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  content: () => JSX.Element;
  submitLable: string;
  cancelLable: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const Popup = (props: PopupProps) => {
  const { heading, isOpen, onClose, doSubmit, content, submitLable, cancelLable } = props;
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={doSubmit}>
          <DialogTitle className="border-b-2 text-sky-800">{heading}</DialogTitle>
          <DialogContent>{content()}</DialogContent>
          <DialogActions className='mr-10 mb-3'>
            <Button type={'submit'} lable={submitLable} buttonType={'primary'} />
            <Button
              type={'button'}
              lable={cancelLable}
              buttonType={'danger'}
              onClick={onClose}
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
export default Popup;
