import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import MuiTable from '../../components/MuiTable/MuiTable';
import SnackBar from '../../components/SnackBar/SnackBar';
import Input from '../../components/Input/Input';
import Popup from '../../components/Popup/Popup';
import Button from '../../components/Button/Button';
import { FaPlus } from 'react-icons/fa';
import { snackBarColors } from '../../config/constants';
const Roles = () => {
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: '',
    type: '',
  });
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  //-------------------------BreadCrumButton Handling-------------------------------------------//



  const BreadCrumButton = () => {
    const content = () => {
      return (
        <>
          <Input
            lable={'Username'}
            type={'text'}
            placeholder={'Enter your Username'}
            value={username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
          />
          <Input
            lable={'Password'}
            type={'text'}
            placeholder={'Enter your category'}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </>
      );
    };
    const doSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log(username, 'username');
      console.log(password, 'password');
      setIsPopUpOpen(false);
      setUsername('');
      setPassword('');
      setSnackBarOpen({
        ...snackBarOpen,
        value: true,
        message: 'Data added successfully!',
        type: snackBarColors.success,
      });
    };
    return (
      <div>
        <Button
          type={'button'}
          onClick={() => setIsPopUpOpen(!isPopUpOpen)}
          lable={'ADD NEW ROLE'}
          buttonType={'primary'}
          icon={<FaPlus />}
        />

        <Popup
          heading={'Enter user details'}
          content={content}
          doSubmit={doSubmit}
          isOpen={isPopUpOpen}
          onClose={() => setIsPopUpOpen(false)}
          submitLable={'SUBMIT'}
          cancelLable={'CANCEL'}
        />
      </div>
    );
  };

  //-------------------------MuiTable Handling-------------------------------------------//

  const columns = [
    { label: 'name', minWidth: 170, align: 'left' },
    { label: 'code', minWidth: 170, align: 'left' },
    {
      label: 'population',
      minWidth: 170,
      align: 'left',
    },
    {
      label: 'viewdetails',
      minWidth: 70,
      align: 'left',
    },
    {
      label: 'action',
      minWidth: 70,
      align: 'left',
    },
  ];
  const rows = [
    { id: 1, name: 'India', code: 'IN', population: 1324171354 },
    { id: 2, name: 'Sunil', code: 'IN', population: 1324171354 },
    { id: 3, name: 'India', code: 'IN', population: 1324171354 },
    { id: 4, name: 'Rahul', code: 'IN', population: 1324171354 },
    { id: 5, name: 'Vinay', code: 'IN', population: 1324171354 },
    { id: 6, name: 'India', code: 'IN', population: 1324171354 },
    { id: 7, name: 'Sunil', code: 'IN', population: 1324171354 },
    { id: 8, name: 'India', code: 'IN', population: 1324171354 },
    { id: 9, name: 'Rahul', code: 'IN', population: 1324171354 },
    { id: 10, name: 'Akhilesh', code: 'IN', population: 1324171354 },
  ];

  const editRecord = (arg: any) => {
    console.log(arg, 'arg');
    setSnackBarOpen({
      ...snackBarOpen,
      value: true,
      message: 'Record update successfully!',
      type: snackBarColors.success,
    });
  };

  const deleteRecord = (arg: any) => {
    console.log(arg, 'arg');
    setSnackBarOpen({
      ...snackBarOpen,
      value: true,
      message: 'Record deleted successfully!',
      type: snackBarColors.warning,
    });
  };

  return (
    <div className="m-4 p-2">
      <SnackBar
        isOpen={snackBarOpen.value}
        handleClose={() => setSnackBarOpen({ ...snackBarOpen, value: false })}
        message={snackBarOpen.message}
        type={snackBarOpen.type}
      />
     
      <BreadCrumb BreadCrumButton={BreadCrumButton()} />
      <div className="my-4 ">
        <MuiTable
          columns={columns}
          rows={rows}
          onEditHandler={editRecord}
          onDeleteHandler={deleteRecord}
        />
      </div>
    </div>
  );
};

export default Roles;
