import  React,{ ReactNode } from 'react';

interface ActionProps {
  lable: string;
  type: 'button' | 'submit' | 'reset';
  buttonType: string;
  onClick?: () => void;
  icon?:ReactNode
}
const Button = (props: ActionProps) => {
  const { lable, onClick, type, buttonType ,icon} = props;
  const actionColor = () => {
    if (buttonType === 'primary') {
      return 'bg-[#1520A6]';
    }
    if (buttonType === 'danger') {
      return 'bg-red-700';
    }
  };
  return (
    <button
      type={type}
      className={`${actionColor()} px-4 py-2 rounded-sm text-white flex items-center`}
      onClick={onClick}
    >
    <div className='mr-1'>{icon}</div>  <div>{lable.toUpperCase()}</div>
    </button>
  );
};

export default Button;
