import { AnyAction } from 'redux';
import { INCREMENT, DECREMENT, UPDATESIDEBARTAB } from '../action';

export interface StateProps {
  counter: number;
  isLoading: boolean;
  defaultSideBarTab: string;
  resposiveSidebar: boolean;
}

const initialState: StateProps = {
  counter: 1000,
  isLoading: false,
  defaultSideBarTab: 'Dashboard',
  resposiveSidebar: false,
};

const Counter = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case DECREMENT:
      return { ...state, counter: state.counter - 1 };
    case UPDATESIDEBARTAB:
      return {
        ...state,
        defaultSideBarTab: action.payload,
      };

    default:
      return state;
  }
};
export default Counter;
