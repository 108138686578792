import React from 'react';

interface InputProps {
  lable: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = (props: InputProps) => {
  const { lable, placeholder, value, type, onChange } = props;
  return (
    <div className="w-[500px] my-1">
      <div className="text-[gray] ml-1 mb-1">{lable}</div>
      <div className="">
        <input
          type={type}
          className="w-full h-10 outline-none border-2 pl-2"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Input;
