import {
  FaHome,
  FaUserTie,
  FaUserFriends,
  FaGlobe,
  FaListAlt,
  FaKey,
  FaFileWord,
  FaBars,
} from 'react-icons/fa';
import user from '../../assets/msd.jpg';
import logo from '../../assets/owlbot_logo_login.png';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StateProps } from '../../redux/reducer';
import { updateSidebarTab } from '../../redux/action';
import { useState } from 'react';
import { colors } from '../../config/constants';
import React from 'react'
const Sidebar = () => {
  const dispatch = useDispatch();
  const [smallSidebar, setSmallSidebar] = useState(false);
  const activeTab = useSelector((state: StateProps) => state.defaultSideBarTab);
  const data = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: <FaHome size={22} />,
    },
    {
      name: 'Roles',
      url: '/roles',
      icon: <FaUserTie size={22} />,
    },
    {
      name: 'Users',
      url: '/users',
      icon: <FaUserFriends size={22} />,
    },
    {
      name: 'Websites',
      url: '/nourl',
      icon: <FaGlobe size={22} />,
    },
    {
      name: 'Categories',
      url: '/nourl',
      icon: <FaListAlt size={22} />,
    },
    {
      name: 'Keywords',
      url: '/nourl',
      icon: <FaKey size={22} />,
    },
    {
      name: 'Noise Words',
      url: '/nourl',
      icon: <FaFileWord size={22} />,
    },
  ];

  const handleOnclick = (item: string) => {
    dispatch(updateSidebarTab(item));
  };

  return (
    <div className="bg-white h-screen">
      <div className="hidden sm:block w-60 shadow-md px-4 pb-4 ">
        <div className={`flex flex-col items-center ${colors.primary}`}>
          <div>
            <img
              src={user}
              width={100}
              height={100}
              className="rounded-full border-2 my-2"
            />
          </div>
          <div className="text-white">Welcome</div>
          <div className="text-white font-bold">Sunil Kumar</div>
        </div>

        {data.map((item, index) => {
          return (
            <Link
              to={item.url}
              onClick={() => handleOnclick(item.name)}
              key={index}
              className={`${
                activeTab === item.name
                  ? `${colors.primary} text-white`
                  : `bg-slate-200`
              } text-[gray] hover:text-white hover:bg-blue-500 flex  items-center border-2 p-2 my-2 cursor-pointer rounded-md`}>
              
               
            
              <div className="mr-4"> {item.icon}</div>
              <div className="">{item.name}</div>
            </Link>
          );
        })}
      </div>
      <div className="bg-white w-56 absolute sm:hidden">
        {data.map((item, index) => {
          return (
            <Link
              to={item.url}
              onClick={() => handleOnclick(item.name)}
              key={index}
              className={`${
                activeTab === item.name ? 'bg-[#1520A6] text-white' : 'bg-slate-200'
              } hover:bg-[#1520A6] text-[gray] hover:text-white flex items-center border-2 p-2 my-2 cursor-pointer rounded-md`}
            >
              <div className="mr-4"> {item.icon}</div>
              <div className="">{item.name}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
