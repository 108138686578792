// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y4D5sNbu8kwgETsWXNh8 {\r\n    background-color: black;\r\n  }\r\n  \r\n  .aYLB694UpbDnlCXLvBxY {\r\n    color: white;\r\n    background-color: #3b82f6;\r\n    font-size: 14px;\r\n    padding: 4px;\r\n    padding-left: 14px;\r\n    font-weight: bold;\r\n  \r\n\r\n  }\r\n  .GnZAKwIADX8f8qXK7BW3{\r\n    font-size: 14px;\r\n    color: grey;\r\n  \r\n  \r\n  }", "",{"version":3,"sources":["webpack://./src/styles/muitable.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;;;EAGnB;EACA;IACE,eAAe;IACf,WAAW;;;EAGb","sourcesContent":[".tableHead {\r\n    background-color: black;\r\n  }\r\n  \r\n  .tableHeadCell {\r\n    color: white;\r\n    background-color: #3b82f6;\r\n    font-size: 14px;\r\n    padding: 4px;\r\n    padding-left: 14px;\r\n    font-weight: bold;\r\n  \r\n\r\n  }\r\n  .tablebody{\r\n    font-size: 14px;\r\n    color: grey;\r\n  \r\n  \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": "Y4D5sNbu8kwgETsWXNh8",
	"tableHeadCell": "aYLB694UpbDnlCXLvBxY",
	"tablebody": "GnZAKwIADX8f8qXK7BW3"
};
export default ___CSS_LOADER_EXPORT___;
