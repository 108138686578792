export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const UPDATESIDEBARTAB = 'UPDATESIDEBARTAB';

export function increment() {
  return {
    type: INCREMENT,
  };
}
export function decrement() {
  return {
    type: DECREMENT,
  };
}
export function updateSidebarTab(tab:string){
  return{
    type:UPDATESIDEBARTAB,
    payload:tab
  }
}

